import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export interface IColumn {
  id: any;
  label: string;
  minWidth?: number;
  align?: "right" | "center";
  format?: (value: any, row: any) => any;
  hidden?: boolean;
  check?: boolean;
  width?: string;
}

export interface ITableComponent {
  columns: IColumn[];
  data: any;
}

export const TableComponent: React.FC<ITableComponent> = ({
  columns,
  data,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ background: "#fff" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                !column.hidden ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontSize: 13,
                      padding: 6,
                      width: column.width,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ) : (
                  <React.Fragment key={column.id} />
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id || row._id}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return !column.hidden ? (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ fontSize: 13, padding: 6 }}
                        >
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      ) : (
                        <React.Fragment key={column.id} />
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
