import React from "react";
import { Routes as ReactRouterDomRoutes, Route } from "react-router-dom";

import {
  RoutesConfig as routesConfig,
  IRoutesConfig,
} from "routes/routes.constant";

import AuthRoute from "routes/auth-route.component";
import NonExistentRoute from "routes/non-existent-route.component";

const Routes: React.FC = () => (
  <ReactRouterDomRoutes>
    {routesConfig.map((routeConfig: IRoutesConfig) =>
      routeConfig.isAuth ? (
        <Route
          {...routeConfig}
          key={routeConfig.path}
          element={<AuthRoute>{routeConfig.element}</AuthRoute>}
        />
      ) : (
        <Route {...routeConfig} key={routeConfig.path} />
      )
    )}

    <Route path="*" element={<NonExistentRoute />} />
  </ReactRouterDomRoutes>
);

export default Routes;
