import { gql } from "@apollo/client";

export const GROUPS = gql`
  query groups {
    groups {
      name
      address
      language
      enabledForSendingMessages
      _id
    }
  }
`;

export const GROUP = gql`
  query groupByID($id: String!) {
    groupByID(id: $id) {
      name
      address
      language
      enabledForSendingMessages
      megaApiInstanceKey
      megaApiInstanceToken
      megaApiInstanceUrl
      phone
      _id
    }
  }
`;
