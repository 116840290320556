import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Box,
  TextField,
  Button,
  FormHelperText,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";

import { useMutation, useLazyQuery } from "@apollo/client";

import { GROUP } from "graphql/groups/groups.query";
import { CREATE_GROUP, UPDATE_GROUP } from "graphql/groups/groups.mutation";

import { useToastAlert } from "context/use-toast-alert.hook";

import * as Yup from "yup";

import { Group } from "pages/groups/interfaces/group.interface";

import { Languages } from "pages/groups/enums/languages.enum";

export const GroupsForm: React.FC = () => {
  const { handleToastAlert } = useToastAlert();
  const { id } = useParams();
  const navigate = useNavigate();

  const [group, setGroup] = useState<Group>();

  const [createGroup] = useMutation(CREATE_GROUP);
  const [updateGroup] = useMutation(UPDATE_GROUP);

  const [loadGroups, { data }] = useLazyQuery(GROUP, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let mounted = true;

    if (mounted && id) {
      loadGroups({ variables: { id } });
    }

    return () => (mounted = false as any);
  }, [loadGroups, id]);

  useEffect(() => {
    if (data) {
      setGroup(data.groupByID);
    }
  }, [data]);

  const handleForm = {
    createGroup: createGroup,
    updateGroup: updateGroup,
  };

  const groupEmpty = {
    _id: "",
    name: "",
    address: "",
    language: Languages.EN,
    enabledForSendingMessages: false,
    megaApiInstanceKey: "",
    megaApiInstanceToken: "",
    megaApiInstanceUrl: "",
    phone: ""
  };

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        background: "white",
        padding: 2,
        borderBottom: "1px solid rgb(222, 226, 230)",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={group?._id ? group : groupEmpty}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          address: Yup.string().required("Address is required"),
          language: Yup.string().required("Language is required"),
          enabledForSendingMessages: Yup.string().required(
            "Enabled for sending messages is required"
          ),
          megaApiInstanceKey: Yup.string().required("MegaAPI Instance Key is required"),
          megaApiInstanceToken: Yup.string().required("MegaAPI Instance Token is required"),
          megaApiInstanceUrl: Yup.string().required("MegaAPI Instance URL is required"),
          phone: Yup.string().required("Phone is required"),
        })}
        onSubmit={(values: Group) => {
          const index = values._id ? "updateGroup" : "createGroup";

          handleForm[index]({
            variables: {
              ...(values._id
                ? {
                    updateGroupInput: {
                      name: values.name,
                      address: values.address,
                      language: values.language,
                      enabledForSendingMessages: values.enabledForSendingMessages,
                      id: values._id,
                      megaApiInstanceKey: values.megaApiInstanceKey,
                      megaApiInstanceToken: values.megaApiInstanceToken,
                      megaApiInstanceUrl: values.megaApiInstanceUrl,
                      phone: values.phone,
                    },
                  }
                : {
                    createGroupInput: {
                      name: values.name,
                      address: values.address,
                      language: values.language,
                      enabledForSendingMessages: values.enabledForSendingMessages,
                      megaApiInstanceKey: values.megaApiInstanceKey,
                      megaApiInstanceToken: values.megaApiInstanceToken,
                      megaApiInstanceUrl: values.megaApiInstanceUrl,
                      phone: values.phone,
                    },
                  }),
            },
            onCompleted: () => {
              handleToastAlert({
                open: true,
                message: "Saved successfully.",
                severity: "success",
              });

              navigate("/groups");
            },
            onError: (error) => {
              handleToastAlert({
                open: true,
                message: error.message,
                severity: "error",
              });
            },
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setValues,
        }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box sx={{ position: "relative", width: "49%" }}>
                <TextField
                  id="name"
                  name="name"
                  variant="standard"
                  placeholder="Group Name"
                  label="Group Name"
                  size="small"
                  sx={{
                    width: "100%",
                    fontSize: 14,
                    "& .MuiInputAdornment-root": { mt: "0 !important" },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  InputProps={{
                    endAdornment: values.name ? (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            padding: 0,
                            border: 0,
                            minWidth: "max-content",
                          }}
                          onClick={() => {
                            setValues(groupEmpty);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                {touched.name && errors.name && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-name"
                    sx={{ position: "absolute" }}
                  >
                    {errors.name}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ position: "relative", width: "49%" }}>
                <TextField
                  id="address"
                  name="address"
                  variant="standard"
                  placeholder="Group Address"
                  label="Group Address"
                  size="small"
                  sx={{
                    width: "100%",
                    fontSize: 14,
                    "& .MuiInputAdornment-root": { mt: "0 !important" },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  InputProps={{
                    endAdornment: values.address ? (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            padding: 0,
                            border: 0,
                            minWidth: "max-content",
                          }}
                          onClick={() => {
                            setValues(groupEmpty);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                {touched.address && errors.address && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-address"
                    sx={{ position: "absolute" }}
                  >
                    {errors.address}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box sx={{ position: "relative", width: "49%" }}>
                <TextField
                  id="megaApiInstanceKey"
                  name="megaApiInstanceKey"
                  variant="standard"
                  placeholder="MegaAPI Instance Key"
                  label="MegaAPI Instance Key"
                  size="small"
                  sx={{
                    width: "100%",
                    fontSize: 14,
                    "& .MuiInputAdornment-root": { mt: "0 !important" },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.megaApiInstanceKey}
                  InputProps={{
                    endAdornment: values.megaApiInstanceKey ? (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            padding: 0,
                            border: 0,
                            minWidth: "max-content",
                          }}
                          onClick={() => {
                            setValues(groupEmpty);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                {touched.megaApiInstanceKey && errors.megaApiInstanceKey && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-megaApiInstanceKey"
                    sx={{ position: "absolute" }}
                  >
                    {errors.megaApiInstanceKey}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ position: "relative", width: "49%" }}>
                <TextField
                  id="megaApiInstanceToken"
                  name="megaApiInstanceToken"
                  variant="standard"
                  placeholder="MegaAPI Instance Key"
                  label="MegaAPI Instance Key"
                  size="small"
                  sx={{
                    width: "100%",
                    fontSize: 14,
                    "& .MuiInputAdornment-root": { mt: "0 !important" },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.megaApiInstanceToken}
                  InputProps={{
                    endAdornment: values.megaApiInstanceToken ? (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            padding: 0,
                            border: 0,
                            minWidth: "max-content",
                          }}
                          onClick={() => {
                            setValues(groupEmpty);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                {touched.megaApiInstanceToken && errors.megaApiInstanceToken && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-megaApiInstanceToken"
                    sx={{ position: "absolute" }}
                  >
                    {errors.megaApiInstanceToken}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box sx={{ position: "relative", width: "49%" }}>
                <TextField
                  id="megaApiInstanceUrl"
                  name="megaApiInstanceUrl"
                  variant="standard"
                  placeholder="MegaAPI Instance URL"
                  label="MegaAPI Instance URL"
                  size="small"
                  sx={{
                    width: "100%",
                    fontSize: 14,
                    "& .MuiInputAdornment-root": { mt: "0 !important" },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.megaApiInstanceUrl}
                  InputProps={{
                    endAdornment: values.megaApiInstanceUrl ? (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            padding: 0,
                            border: 0,
                            minWidth: "max-content",
                          }}
                          onClick={() => {
                            setValues(groupEmpty);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                {touched.megaApiInstanceUrl && errors.megaApiInstanceUrl && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-megaApiInstanceUrl"
                    sx={{ position: "absolute" }}
                  >
                    {errors.megaApiInstanceUrl}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ position: "relative", width: "49%" }}>
                <TextField
                  id="phone"
                  name="phone"
                  variant="standard"
                  placeholder="Phone"
                  label="Phone"
                  size="small"
                  sx={{
                    width: "100%",
                    fontSize: 14,
                    "& .MuiInputAdornment-root": { mt: "0 !important" },
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  InputProps={{
                    endAdornment: values.phone ? (
                      <InputAdornment position="start">
                        <Button
                          sx={{
                            padding: 0,
                            border: 0,
                            minWidth: "max-content",
                          }}
                          onClick={() => {
                            setValues(groupEmpty);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                  }}
                />
                {touched.phone && errors.phone && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-phone"
                    sx={{ position: "absolute" }}
                  >
                    {errors.phone}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Box sx={{ position: "relative", width: "49%" }}>
                <FormControl
                  variant="standard"
                  sx={{ mb: 2, minWidth: "100%" }}
                >
                  <InputLabel id="select-language-label">Language</InputLabel>
                  <Select
                    labelId="select-language-label"
                    name="language"
                    id="select-language"
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Language"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.values(Languages).map((language: Languages) => (
                      <MenuItem key={language} value={language}>
                        {language}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.language && errors.language && (
                  <FormHelperText
                    error
                    id="select-language"
                    sx={{ position: "absolute" }}
                  >
                    {errors.language}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ position: "relative", width: "49%" }}>
                <FormControlLabel
                  control={
                    <Switch
                      value={values.enabledForSendingMessages}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="enabledForSendingMessages"
                      checked={values.enabledForSendingMessages}
                      id="enabledForSendingMessages"
                    />
                  }
                  label="Enabled for sending messages"
                />

                {touched.enabledForSendingMessages &&
                  errors.enabledForSendingMessages && (
                    <FormHelperText
                      error
                      id="enabledForSendingMessages"
                      sx={{ position: "absolute" }}
                    >
                      {errors.enabledForSendingMessages}
                    </FormHelperText>
                  )}
              </Box>
            </Box>
            <Button
              disableElevation
              variant="contained"
              type="submit"
              disabled={!values.name || !values.address}
            >
              Save
            </Button>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
