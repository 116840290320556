import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
  mutation createGroup($createGroupInput: CreateGroupInput!) {
    createGroup(createGroupInput: $createGroupInput) {
      name
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroupByID($updateGroupInput: UpdateGroupInput!) {
    updateGroupByID(updateGroupInput: $updateGroupInput) {
      name
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation removeGroupByID($id: String!) {
    removeGroupByID(id: $id) {
      name
    }
  }
`;
