export enum EQueryStringKey {
  StartDate = 'startDate',
  FinalDate = 'finalDate',
  PageBy = 'pageBy',
  Group = 'group',
  DomainsGroup = 'domainsGroup',
  URLSGroup = 'urlsGroup',
  SearchTerm = 'searchTerm',
  Field = 'field',
  Order = 'order',
  FilterType = 'filterType',
  SearchTable = 'searchTable',
  GoogleAuthResult = 'result',
  GoogleAccessToken = 'access_token',
  SizePerPage = 'sizePerPage',
  PageNumber = 'pageNumber',
  AdUnit = 'adUnit',
  Campaign = 'campaign',
  Niche = 'niche',
  NoTrafficArchived = 'noTrafficArchived',
  AllSites = 'allSites'
}

export enum EQueryStringValue {
  Empty = '',
  Day = 'day',
  GoCampaign = 'goCampaign',
  FbCampaign = 'fbCampaign',
  Hour = 'hour',
  Term = 'term',
  Success = 'success',
  HourlyAverage = 'hourlyAverage',
  Organic = 'organic',
  Optimized = 'optimized',
  ArTag = 'arTag'
}

export class QueryStringService {
  private static getUrl() {
    const url = new URL(window.location.toString());
    return url;
  }

  static get<T>(key: string): T | null {
    const url = this.getUrl();
    const value: any = url.searchParams.get(key);

    if (value) {
      return value as T;
    }

    return null;
  }

  static getArray<T>(key: string): Array<T> | null {
    const url = this.getUrl();
    const value = url.searchParams.get(key);
    const arrayValue: any = value?.split(' ');

    if (arrayValue) {
      return arrayValue as Array<T>;
    }

    return null;
  }

  static set(key: string, value: string): void {
    const url = this.getUrl();
    url.searchParams.set(key, value);
    window.history.replaceState(null, '', url.toString());
  }

  static setArray(key: string, value: Array<any>): void {
    const url = this.getUrl();
    const arrayValue = value.join(' ');
    url.searchParams.set(key, arrayValue);
    window.history.replaceState(null, '', url.toString());
  }

  static delete(key: string) {
    const url = this.getUrl();
    url.searchParams.delete(key);
    window.history.replaceState(null, '', url.toString());
  }

  static isQueryStringEnumValue(
    value: any,
    enumeration: { [item: string]: string }
  ) {
    return value === enumeration[value];
  }

  static verifyQueryStringEnumValues(
    values: Array<any>,
    enumeration: { [item: string]: string | number }
  ) {
    return values.filter((value: any) => value === enumeration[value]);
  }

  static verifyQuerStringRangeValues(
    rangeValues: Array<string>,
    defaultFirstValue: number,
    defaultSeccondValue: number
  ) {
    let parsedFirstRangeValue;
    let parsedSecondRangeValue;

    const firstRangePosition = rangeValues[0];
    const secondRangePosition = rangeValues[1];

    if (firstRangePosition && !Number.isNaN(Number(firstRangePosition))) {
      parsedFirstRangeValue = parseFloat(firstRangePosition);
    } else {
      parsedFirstRangeValue = defaultFirstValue;
    }

    if (secondRangePosition && !Number.isNaN(Number(secondRangePosition))) {
      parsedSecondRangeValue = parseFloat(secondRangePosition);
    } else {
      parsedSecondRangeValue = defaultSeccondValue;
    }

    const verifiedRangeValue = [parsedFirstRangeValue, parsedSecondRangeValue];

    return verifiedRangeValue;
  }
}
