import React from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "components/alert/alert.component";
import { useToastAlert } from "context/use-toast-alert.hook";

export const ToastAlert: React.FC = () => {
  const { openToast, handleClose, alertMessage, alertSeverity } =
    useToastAlert();

  return (
    <Snackbar open={openToast} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={alertSeverity}
        sx={{ width: "100%" }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};
