import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Avatar, Box, Typography, Container } from "@mui/material";

import { Input, Button, FormControl } from "@mui/joy";

import { AUTH } from "graphql/auth/auth.mutation";

import { useToastAlert } from "context/use-toast-alert.hook";
import { client } from "graphql/apollo-api-gateway";

import { ToastAlert } from "components/toast-alert/toast-alert.component";
import { GoogleLoginButton } from "components/google-login-button/google-login-button.component";

export const SignIn: React.FC = () => {
  const { handleToastAlert } = useToastAlert();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onCompleted = (data: any) => {
    localStorage.setItem("auth-token", data.authenticator.access_token);
    navigate("/groups");
  };

  const onError = () => {
    handleToastAlert({
      open: true,
      message:
        "Parece que o endereço de e-mail ou a senha inseridos estão incorretos, por favor verifique e tente novamente.",
      severity: "error",
    });
  };

  const [auth] = useMutation(AUTH, {
    onCompleted,
    onError,
    client,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    auth({
      variables: { email, password, userData: "{}" },
    });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: 4,
            borderRadius: "4px",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box sx={{ mt: 1, width: "100%" }}>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Input
                  required
                  placeholder="E-mail"
                  name="email"
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                  sx={{
                    borderRadius: 4,
                    width: "100%",
                  }}
                />
              </FormControl>

              <FormControl>
                <Input
                  required
                  placeholder="Password"
                  name="password"
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  sx={{
                    borderRadius: 4,
                    mt: 1,
                    width: "100%",
                  }}
                />
              </FormControl>

              <FormControl>
                <Button
                  variant="solid"
                  color="primary"
                  sx={{ mt: 2, mb: 2, borderRadius: 4 }}
                  disabled={!email || !password}
                  type="submit"
                >
                  Login
                </Button>
              </FormControl>

              <GoogleLoginButton />
            </form>
          </Box>
        </Box>
      </Container>

      <ToastAlert />
    </Box>
  );
};
