import { gql } from "@apollo/client";

export const MESSAGES = gql`
  query messages {
    messages {
      _id
      message
      title
      language
    }
  }
`;

export const MESSAGE = gql`
  query messageByID($id: String!) {
    messageByID(id: $id) {
      _id
      message
      title
      language
    }
  }
`;
