import styled from "@emotion/styled";

export const ErrorBox = styled.div`
  text-align: center;
  margin-top: 6%;
`;

export const ErrorHead = styled.div`
  font-size: 26px;
  font-weight: 300;
`;

export const ErrorIcon = styled.span`
  color: #d73925;
  margin-right: 8px;
`;

export const ErrorBody = styled.div`
  font-size: 18px !important;

  & a {
    font-size: 18px !important;
  }
`;
