import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
// import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
// import { createClient } from "graphql-ws";

const apiHttpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
});

// const apiWsLink = new GraphQLWsLink(
//   createClient({
//     url: process.env.REACT_APP_API_WS || "",
//   })
// );

const systemsAuthLink = setContext((_, { headers }) => {
  const token =
    localStorage.getItem("auth-token") || process.env.REACT_APP_TOKEN;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition"
      // &&
      // definition.operation === "subscription"
    );
  },
  // apiWsLink,
  systemsAuthLink.concat(apiHttpLink)
);

const cache = new InMemoryCache({
  typePolicies: {
    // Subscription: {
    //   fields: {},
    // },
    Query: {
      fields: {},
    },
  },
});

const client = new ApolloClient({
  link: splitLink,
  cache,
});

export { client };
