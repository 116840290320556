import React from "react";
import { Navigate, useLocation } from "react-router-dom";
// import { AuthAccountService } from 'services/auth/AuthAccountService';

const NonExistentRoute: React.FC = () => {
  const isLogged = false; //AuthAccountService.useIsLogged();
  const { pathname } = useLocation();

  /*
    Work around to handle with reload (F5) that for some reason redirect to /route-name/index.html in pages configured with pre-render for SEO in production
  */
  if (pathname.includes("index.html")) {
    const splittedPathname = pathname.split("/index.html");
    const route = splittedPathname[0];
    const queryStringParams = window.location.search;

    const path = `${route}${queryStringParams}`;

    return <Navigate to={path} />;
  }

  if (isLogged) {
    return <Navigate to="/assets" />;
  }

  return <Navigate to="/" />;
};

export default NonExistentRoute;
