import React from "react";
import { Container } from "@mui/material";
import { Header } from "components/header/header.component";
import { ToastAlert } from "components/toast-alert/toast-alert.component";

import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

import { ME } from "graphql/auth/auth.mutation";
import { client } from "graphql/apollo-api-gateway";

const AuthRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { called, loading, data } = useQuery(ME, {
    fetchPolicy: "network-only",
    client,
  });

  if (loading) return <></>;

  // if (called && !data?.me.email) return <Navigate to="/" />;

  return (
    <>
      <Header />
      <Container>{children}</Container>
      <ToastAlert />
    </>
  );
};

export default AuthRoute;
