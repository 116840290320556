import { SignIn } from "pages/sign-in/sign-in-page";
import { GroupsForm } from "pages/groups/groups.form";
import { GroupsPage } from "pages/groups/groups.page";
import { MessagesPage } from "pages/messages/messages.page";
import { MessageForm } from "pages/messages/message.form";
import { RedirectForm } from "pages/redirect/redirect.form";

export interface IRoutesConfig {
  isAuth?: boolean;
  path: string;
  element: JSX.Element;
  exact: boolean;
  label: string;
  showMenu: boolean;
  highlightMenuInRoute?: string;
  icon?: JSX.Element;
}

export const RoutesConfig: IRoutesConfig[] = [
  {
    path: "/",
    element: <SignIn />,
    exact: true,
    label: "Login",
    showMenu: true,
    isAuth: false,
  },
  {
    path: "/groups",
    element: <GroupsPage />,
    exact: true,
    label: "Groups",
    showMenu: true,
    isAuth: true,
  },
  {
    path: "/groups/form",
    element: <GroupsForm />,
    exact: true,
    label: "Groups",
    showMenu: true,
    isAuth: true,
  },
  {
    path: "/groups/form/:id",
    element: <GroupsForm />,
    exact: true,
    label: "Groups",
    showMenu: true,
    isAuth: true,
  },
  {
    path: "/messages",
    element: <MessagesPage />,
    exact: true,
    label: "Messages",
    showMenu: true,
    isAuth: true,
  },
  {
    path: "/messages/form",
    element: <MessageForm />,
    exact: true,
    label: "Add Message",
    showMenu: true,
    isAuth: true,
  },
  {
    path: "/messages/form/:id",
    element: <MessageForm />,
    exact: true,
    label: "Edit Message",
    showMenu: true,
    isAuth: true,
  },
  {
    path: "/redirect/form",
    element: <RedirectForm />,
    exact: true,
    label: "Update Message",
    showMenu: true,
    isAuth: true,
  },
];
