export enum Languages {
  AR = "AR", // Arabic
  AZ = "AZ", // Azerbaijani
  BN = "BN", // Bengali
  CEB = "CEB", // Cebuano
  DE = "DE", // German
  EN = "EN", // English
  ES = "ES", // Spanish
  FA = "FA", // Persian
  FI = "FI", // Finnish
  FR = "FR", // French
  HA = "HA", // Hausa
  HE = "HE", // Hebrew
  HI = "HI", // Hindi
  HU = "HU", // Hungarian
  ID = "ID", // Indonesian
  IT = "IT", // Italian
  JA = "JA", // Japanese
  JV = "JV", // Javanese
  MS = "MS", // Malay
  NL = "NL", // Dutch
  PA = "PA", // Punjabi
  PL = "PL", // Polish
  PT = "PT", // Portuguese
  RO = "RO", // Romanian
  RU = "RU", // Russian
  SH = "SH", // Serbo-Croatian
  SK = "SK", // Slovak
  SV = "SV", // Swedish
  TA = "TA", // Tamil
  TH = "TH", // Thai
  TL = "TL", // Tagalog
  TR = "TR", // Turkish
  UR = "UR", // Urdu
  VI = "VI", // Vietnamese
  ZH = "ZH", // Mandarin Chinese
}
