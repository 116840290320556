import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { Add, Edit, Circle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "components/table/table.component";
import { StyledIconButton } from "components/icon-button/icon-button.component";

import { GROUPS } from "graphql/groups/groups.query";

import { Group } from "pages/groups/interfaces/group.interface";
import { DeleteButton } from "components/delete-button/delete-button.component";

export const GroupsPage: React.FC = () => {
  const navigate = useNavigate();

  const [groups, setGroups] = useState<Group[]>([]);

  const [loadGroups, { data, loading, refetch }] = useLazyQuery(GROUPS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      loadGroups();
    }

    return () => (mounted = false as any);
  }, [loadGroups]);

  useEffect(() => {
    if (data) {
      setGroups(data.groups);
    }
  }, [data]);

  const columns = [
    {
      id: "enabledForSendingMessages",
      label: "",
      width: "20px",
      format: (cell: boolean) => <Circle color={cell ? "success" : "error"} />,
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "address",
      label: "Address",
    },
    {
      id: "language",
      label: "Language",
    },
    {
      id: "_id",
      label: "Actions",
      width: "10%",
      format: (cell: string) => {
        return (
          <>
            <StyledIconButton
              variant="contained"
              color="warning"
              sx={{ "& svg": { width: 13, height: 13 } }}
              onClick={() => navigate(`/groups/form/${cell}`)}
            >
              <Edit fontSize="small" />
            </StyledIconButton>
            <DeleteButton id={cell} refetch={refetch} page="group" />
          </>
        );
      },
    },
  ];

  if (loading) return <></>;

  return (
    <Box>
      <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
        <StyledIconButton
          variant="contained"
          color="primary"
          sx={{ "& svg": { width: 16, height: 16 } }}
          onClick={() => navigate("/groups/form")}
        >
          <Add fontSize="small" />
        </StyledIconButton>
      </Box>
      <TableComponent columns={columns} data={groups} />
    </Box>
  );
};
