import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import { useMutation, useLazyQuery } from "@apollo/client";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { useToastAlert } from "context/use-toast-alert.hook";

import { Message } from "pages/messages/interfaces/messages.interface";

import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
} from "graphql/messages/messages.mutation";

import { MESSAGE } from "graphql/messages/messages.query";

import { Languages } from "pages/groups/enums/languages.enum";

export const MessageForm: React.FC = () => {
  const { handleToastAlert } = useToastAlert();
  const { id } = useParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState<Message>();

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [updateMessage] = useMutation(UPDATE_MESSAGE);

  const [loadMessages, { data }] = useLazyQuery(MESSAGE, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let mounted = true;

    if (mounted && id) {
      loadMessages({ variables: { id } });
    }

    return () => (mounted = false as any);
  }, [loadMessages, id]);

  useEffect(() => {
    if (data) {
      setMessage(data.messageByID);
    }
  }, [data]);

  const handleForm = {
    createMessage: createMessage,
    updateMessage: updateMessage,
  };

  const messageEmpty = {
    title: "",
    message: "",
    language: Languages.AR,
  };

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        background: "white",
        padding: 2,
        borderBottom: "1px solid rgb(222, 226, 230)",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={message?._id ? message : messageEmpty}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
          message: Yup.string().required("Message is required"),
          language: Yup.string().required("Language is required"),
        })}
        onSubmit={(values: Message) => {
          const index = values._id ? "updateMessage" : "createMessage";

          handleForm[index]({
            variables: {
              ...(values._id
                ? {
                    updateMessageInput: {
                      title: values.title,
                      message: values.message,
                      language: values.language,
                      id: values._id,
                    },
                  }
                : {
                    createMessageInput: {
                      title: values.title,
                      message: values.message,
                      language: values.language,
                    },
                  }),
            },
            onCompleted: () => {
              handleToastAlert({
                open: true,
                message: "Saved successfully.",
                severity: "success",
              });

              navigate("/messages");
            },
            onError: (error) => {
              handleToastAlert({
                open: true,
                message: error.message,
                severity: "error",
              });
            },
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "59%",
                }}
              >
                <TextField
                  id="title"
                  name="title"
                  label="Title"
                  variant="standard"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  sx={{
                    width: "100%",
                  }}
                />
                {touched.title && errors.title && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-name"
                    sx={{ position: "absolute" }}
                  >
                    {errors.title}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ position: "relative", width: "39%" }}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <InputLabel id="select-language-label">Language</InputLabel>
                  <Select
                    labelId="select-language-label"
                    name="language"
                    id="select-language"
                    value={values.language}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Language"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.values(Languages).map((language: Languages) => (
                      <MenuItem key={language} value={language}>
                        {language}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.language && errors.language && (
                  <FormHelperText
                    error
                    id="select-language"
                    sx={{ position: "absolute" }}
                  >
                    {errors.language}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <TextField
                id="message"
                name="message"
                label="Message"
                variant="standard"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.message}
                rows={20}
                multiline
                sx={{ my: 2, width: "100%" }}
              />
              {touched.message && errors.message && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-name"
                  sx={{ position: "absolute" }}
                >
                  {errors.message}
                </FormHelperText>
              )}
            </Box>

            <Button
              disableElevation
              variant="contained"
              type="submit"
              sx={{
                width: "100px",
              }}
              disabled={!values.message || !values.title}
            >
              Save
            </Button>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
