import { gql } from "@apollo/client";

export const AUTH = gql`
  mutation authenticator(
    $email: String!
    $password: String!
    $userData: String!
  ) {
    authenticator(
      input: { email: $email, password: $password, userData: $userData }
    ) {
      access_token
    }
  }
`;

export const ME = gql`
  query me {
    me {
      id
      email
      name
    }
  }
`;
