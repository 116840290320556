import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  FormHelperText
} from "@mui/material";
import { Formik } from "formik";
import { useMutation, useLazyQuery } from "@apollo/client";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { useToastAlert } from "context/use-toast-alert.hook";

import { Redirect } from "pages/redirect/interfaces/redirect.interface";

import { UPDATE_REDIRECT } from "graphql/redirects/redirects.mutation";

import { REDIRECT } from "graphql/redirects/redirects.query";

interface FormattedRedicts {
  idEnglish?:string,
  urlEnglish?: string;
  idSpanish?: string;
  urlSpanish?: string;
}

export const RedirectForm: React.FC = () => {
  const { handleToastAlert } = useToastAlert();
  const navigate = useNavigate();

  const [redirects, setRedirects] = useState<Redirect[]>([]);
  const [formattedRedicts, setFormattedRedicts] = useState<FormattedRedicts>();

  const [updateRedirects] = useMutation(UPDATE_REDIRECT);

  const [loadRedirects, { data }] = useLazyQuery(REDIRECT, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      loadRedirects();
    }

    return () => (mounted = false as any);
  }, [loadRedirects]);

  useEffect(() => {
    if (data) {
      setRedirects(data.redirects);

      setFormattedRedicts({
        idEnglish: data?.redirects[0]._id || "",
        urlEnglish: data?.redirects[0].url || "",
        idSpanish: data?.redirects[1]._id || "",
        urlSpanish: data?.redirects[1].url || "",
      });
    }
  }, [data]);

  const handleForm = {
    updateRedirects: updateRedirects,
  };

  const redirectsEmpty = {
    idEnglish: "",
    urlEnglish: "",
    idSpanish: "",
    urlSpanish: "",
  };

  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        background: "white",
        padding: 2,
        borderBottom: "1px solid rgb(222, 226, 230)",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={formattedRedicts ? formattedRedicts : redirectsEmpty}
        validationSchema={Yup.object().shape({
          idEnglish: Yup.string().required("English ID is required"),
          urlEnglish: Yup.string().required("English URL is required"),
          idSpanish: Yup.string().required("Spanish ID is required"),
          urlSpanish: Yup.string().required("Spanish  is required"),
        })}
        onSubmit={(values: FormattedRedicts) => {
          const index = "updateRedirects";

          handleForm[index]({
            variables: {
              ...(values.idEnglish
                ? {
                  updateRedirectsInput: {
                      idEnglish: values.idEnglish,
                      urlEnglish: values.urlEnglish,
                      idSpanish: values.idSpanish,
                      urlSpanish: values.urlSpanish,
                    },
                  }
                : {
                    updateRedirectsInput: {
                      idEnglish: values.idEnglish,
                      urlEnglish: values.urlEnglish,
                      idSpanish: values.idSpanish,
                      urlSpanish: values.urlSpanish,
                    },
                  }),
            },
            onCompleted: () => {
              handleToastAlert({
                open: true,
                message: "Saved successfully.",
                severity: "success",
              });

              navigate("/redirect/form");
            },
            onError: (error) => {
              handleToastAlert({
                open: true,
                message: error.message,
                severity: "error",
              });
            },
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >

              <TextField
                id="idEnglish"
                name="idEnglish"
                variant="standard"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.idEnglish}
                type="hidden"
              />

              <TextField
                id="idSpanish"
                name="idSpanish"
                variant="standard"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.idSpanish}
                type="hidden"
              />

              <Box
                sx={{
                  width: "100%",
                }}
              >
                <TextField
                  id="urlEnglish"
                  name="urlEnglish"
                  label="URL English"
                  variant="standard"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.urlEnglish}
                  sx={{
                    width: "100%",
                  }}
                />
                {touched.urlEnglish && errors.urlEnglish && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-name"
                    sx={{ position: "absolute" }}
                  >
                    {errors.urlEnglish}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <TextField
                  id="urlSpanish"
                  name="urlSpanish"
                  label="URL Spanish"
                  variant="standard"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.urlSpanish}
                  sx={{
                    width: "100%",
                  }}
                />
                {touched.urlSpanish && errors.urlSpanish && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-name"
                    sx={{ position: "absolute" }}
                  >
                    {errors.urlSpanish}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <br />
            <Button
              disableElevation
              variant="contained"
              type="submit"
              sx={{
                width: "100px",
              }}
              disabled={!values.urlSpanish}
            >
              Save
            </Button>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
