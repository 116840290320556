import { gql } from "@apollo/client";

export const CREATE_MESSAGE = gql`
  mutation createMessage($createMessageInput: CreateMessageInput!) {
    createMessage(createMessageInput: $createMessageInput) {
      _id
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation updateMessageByID($updateMessageInput: UpdateMessageInput!) {
    updateMessageByID(updateMessageInput: $updateMessageInput) {
      _id
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation removeMessageByID($id: String!) {
    removeMessageByID(id: $id) {
      _id
    }
  }
`;
