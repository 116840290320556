import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { Add, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "components/table/table.component";
import { StyledIconButton } from "components/icon-button/icon-button.component";
import { DeleteButton } from "components/delete-button/delete-button.component";

import { MESSAGES } from "graphql/messages/messages.query";

import { Group } from "pages/groups/interfaces/group.interface";

export const MessagesPage: React.FC = () => {
  const navigate = useNavigate();

  const [messages, setMessages] = useState<Group[]>([]);

  const [loadMessages, { data, loading, refetch }] = useLazyQuery(MESSAGES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      loadMessages();
    }

    return () => (mounted = false as any);
  }, [loadMessages]);

  useEffect(() => {
    if (data) {
      setMessages(data.messages);
    }
  }, [data]);

  const columns = [
    {
      id: "title",
      label: "Title",
      width: "30%",
    },
    {
      id: "message",
      label: "Message",
      width: "50%",
    },
    {
      id: "language",
      label: "Language",
      width: "10%",
    },
    {
      id: "_id",
      label: "Actions",
      width: "10%",
      format: (cell: string, row: any) => {
        return (
          <>
            <StyledIconButton
              variant="contained"
              color="warning"
              sx={{ "& svg": { width: 13, height: 13 } }}
              onClick={() => navigate(`/messages/form/${cell}`)}
            >
              <Edit fontSize="small" />
            </StyledIconButton>
            <DeleteButton id={cell} refetch={refetch} page="message" />
          </>
        );
      },
    },
  ];

  if (loading) return <></>;

  return (
    <Box>
      <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
        <StyledIconButton
          variant="contained"
          color="primary"
          sx={{ "& svg": { width: 16, height: 16 } }}
          onClick={() => navigate("/messages/form")}
        >
          <Add fontSize="small" />
        </StyledIconButton>
      </Box>
      <TableComponent columns={columns} data={messages} />
    </Box>
  );
};
