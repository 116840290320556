import React from "react";
import { StyledIconButton } from "components/icon-button/icon-button.component";
import { Delete } from "@mui/icons-material";

import { useMutation } from "@apollo/client";

import { DELETE_GROUP } from "graphql/groups/groups.mutation";
import { DELETE_MESSAGE } from "graphql/messages/messages.mutation";
import { useToastAlert } from "context/use-toast-alert.hook";

interface DeleteButtonProps {
  id: string;
  page: "group" | "message";
  refetch: () => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  id,
  page,
  refetch,
}) => {
  const { handleToastAlert } = useToastAlert();

  const onCompleted = () => {
    handleToastAlert({
      open: true,
      message: "Saved successfully.",
      severity: "success",
    });

    refetch();
  };

  const onError = (error: any) => {
    handleToastAlert({
      open: true,
      message: error.message,
      severity: "error",
    });
  };

  const mutations = {
    group: DELETE_GROUP,
    message: DELETE_MESSAGE,
  };

  const [deleteGroup] = useMutation(mutations[page], {
    variables: { id },
    onCompleted,
    onError,
  });

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure about this action?")) deleteGroup();
  };

  return (
    <StyledIconButton
      variant="contained"
      color="error"
      sx={{ "& svg": { width: 13, height: 13 }, ml: 1 }}
      onClick={() => handleDelete()}
    >
      <Delete fontSize="small" />
    </StyledIconButton>
  );
};
