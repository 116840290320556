import { Box } from "@mui/material";
import React from "react";
import {
  QueryStringService,
  EQueryStringKey,
  EQueryStringValue,
} from "utils/QueryStringService";

enum EEnvironment {
  PROD = "msg.adireto.com",
  STAGING = "msg-staging.adireto.com",
  LOCAL = "localhost",
}

export const GoogleLoginButton: React.FC = () => {
  const defineGoogleAuthUrl = () => {
    const { hostname } = window.location;

    const authHost: Record<EEnvironment, string> = {
      [EEnvironment.PROD]: "https://auth.adireto.com",
      [EEnvironment.STAGING]: "http://auth-staging.adireto.com",
      [EEnvironment.LOCAL]: "http://auth-staging.adireto.com",
    };

    const redirect: Record<EEnvironment, string> = {
      [EEnvironment.PROD]: `https://${EEnvironment.PROD}`,
      [EEnvironment.STAGING]: `https://${EEnvironment.STAGING}`,
      [EEnvironment.LOCAL]: `http://${EEnvironment.LOCAL}:3000`,
    };

    return `${authHost[hostname as EEnvironment]}/google-login?redirectURL=${
      redirect[hostname as EEnvironment]
    }`;
  };

  const handleWithGoogleAuthInitialization = () => {
    localStorage.removeItem("auth-token");

    const googleAuthResult =
      QueryStringService.get<string>(EQueryStringKey.GoogleAuthResult) || "";

    if (googleAuthResult === EQueryStringValue.Success) {
      const googleAuthToken =
        QueryStringService.get<string>(EQueryStringKey.GoogleAccessToken) || "";

      localStorage.setItem("auth-token", googleAuthToken);

      window.location.href = "/groups";
    }
  };

  handleWithGoogleAuthInitialization();

  return (
    <Box
      sx={{
        width: "100%",
        height: "30px",
        backgroundColor: "rgb(255, 255, 255)",
        borderRadius: "0.25rem",
        border: "1px solid rgb(194, 194, 194)",
        cursor: "pointer",
        "&:hover ": {
          boxShadow: "0 0 6px #C05842",
        },
      }}
    >
      <Box
        component="a"
        href={defineGoogleAuthUrl()}
        sx={{
          textDecoration: "none",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            marginLeft: "4px",
            width: "28px",
            height: "28px",
            borderRadius: "0.25rem",
            backgroundColor: "rgb(255, 255, 255)",
            textDecoration: "none",
          }}
        >
          <Box
            component="img"
            alt="google-login"
            src="https://dash-beta.adireto.com/static/media/google_logo.2cac4c27.png"
            sx={{
              position: "absolute",
              marginTop: "5px",
              marginLeft: "5px",
              width: "18px",
              height: "18px",
            }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            paddingTop: "6px",
            color: "rgb(192, 88, 66)",
            marginBottom: "0px",
            fontSize: "14px",
            textDecoration: "none",
          }}
        >
          <b>Continue with Google</b>
        </Box>
      </Box>
    </Box>
  );
};
