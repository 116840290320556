import React from "react";
import { Box, Container } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

export const Header: React.FC = () => {
  const location = useLocation();

  return (
    <Box
      style={{
        backgroundColor: "#fff",
        borderBottom: "1px solid #dee2e6",
        padding: ".5rem .5rem",
        minHeight: 37,
      }}
    >
      <Container
        sx={{
          height: 37,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color: "rgba(0,0,0,.5)",
            fontWeight: 600,
            height: 37,
            display: "flex",
            alignItems: "center",
            xs: {
              display: "flex",
              flexDirection: "column !important",
            },
          }}
        >
          Adzapp
        </Box>

        <Box component="nav">
          <Box
            component="ul"
            sx={{
              display: "flex",
              listStyle: "none",
              padding: 0,
              fontSize: 14,
              "& a": {
                textDecoration: "none",
                color: "#333",
              },
            }}
          >
            <Box
              component="li"
              sx={{
                "& a": {
                  fontWeight: location.pathname.includes("groups")
                    ? "bold"
                    : "normal",
                },
              }}
            >
              <Link to="/groups">Groups</Link>
            </Box>
            <Box
              component="li"
              sx={{
                ml: 2,
                "& a": {
                  fontWeight: location.pathname.includes("messages")
                    ? "bold"
                    : "normal",
                },
              }}
            >
              <Link to="/messages">Messages</Link>
            </Box>
            <Box
              component="li"
              sx={{
                ml: 2,
                "& a": {
                  fontWeight: location.pathname.includes("redirect")
                    ? "bold"
                    : "normal",
                },
              }}
            >
              <Link to="/redirect/form">Redirect</Link>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ ml: 1 }}>
            <Link to="/">
              <Logout color="info" />
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
