import React from "react";
import { Link } from "react-router-dom";

import {
  ErrorBox,
  ErrorHead,
  ErrorIcon,
  ErrorBody,
} from "components/error-boundary/error-boundary.styles";

type Props = {
  data?: any;
  errorReport?: any;
  children?: any;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState(
      {
        hasError: true,
      },
      () => {
        if (process.env.NODE_ENV === "production") {
          this.props.errorReport({
            variables: {
              email: this.props.data.me.email,
              username: this.props.data.me.name,
              url: window.location.href,
              error: error.toString(),
              stacktrace: errorInfo.componentStack,
            },
          });
        }
      }
    );
  }

  handleRedirect = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBox>
          <ErrorHead>
            <ErrorIcon>
              <i className="fas fa-exclamation-triangle fa-2x	" />
            </ErrorIcon>
            Oops! Something went wrong.
          </ErrorHead>
          <ErrorBody>
            The developers have already been warned and they will work on fixing
            that right away.
            <br />
            Meanwhile, you may return to{" "}
            <Link to="/" onClick={this.handleRedirect}>
              Login
            </Link>
          </ErrorBody>
        </ErrorBox>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
