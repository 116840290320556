import { gql } from "@apollo/client";

export const REDIRECT = gql`
  query redirects {
    redirects {
      _id
      url
    }
  }
`;
