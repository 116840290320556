import React, { createContext, useState } from "react";

interface IHandleToastAlert {
  message: string;
  severity: "success" | "info" | "warning" | "error";
  open: boolean;
}

interface IToastAlertContextData {
  openToast: boolean;
  setOpenToast: React.Dispatch<React.SetStateAction<boolean>>;
  alertMessage: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  alertSeverity: "success" | "info" | "warning" | "error";
  setAlertSeverity: React.Dispatch<
    React.SetStateAction<"success" | "info" | "warning" | "error">
  >;
  handleClose: () => void;
  handleToastAlert: (props: IHandleToastAlert) => void;
}

interface IToastAlertProvider {
  children: any;
}

export const ToastAlertContext = createContext<IToastAlertContextData>(
  {} as IToastAlertContextData
);

export const ToastProvider: React.FC<IToastAlertProvider> = ({ children }) => {
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("error");

  const handleClose = () => setOpenToast(false);

  const handleToastAlert = ({ open, message, severity }: IHandleToastAlert) => {
    setOpenToast(open);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  return (
    <ToastAlertContext.Provider
      value={{
        openToast,
        setOpenToast,
        handleClose,
        handleToastAlert,
        alertMessage,
        setAlertMessage,
        alertSeverity,
        setAlertSeverity,
      }}
    >
      {children}
    </ToastAlertContext.Provider>
  );
};
