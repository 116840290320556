import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import ErrorBoundary from "components/error-boundary/error-boundary.component";
import Routes from "routes/routes.component";

import { client } from "graphql/apollo";
import { ToastProvider } from "store/toast-alert.context";

export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ErrorBoundary>
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>
  );
};
